/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
.list-group {
  i {
    margin-right: $list-group-margin;
  }
  img {
    height: 40px;
    width: 40px;
    margin-right: $list-group-margin;
  }
}
/**=====================
     17. List CSS Ends
==========================**/
