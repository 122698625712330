/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

// *** utils ***

@import 'utils/variables';

:root {
  --theme-deafult: #7366ff;
  --theme-secondary: #f73164;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2f2f3b;
  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;

  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
}

body[class='dark-only'],
body.dark-only {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  -semi-dark: #1d1e26;
}

// *** base ***
@import 'base/reset';
@import 'base/typography';

//  *** components ***
@import 'components/according';
@import 'components/alert';
@import 'components/avatars';
@import 'components/badge';
@import 'components/bookmark';
@import 'components/breadcrumb';
@import 'components/builders';

@import 'components/buttons';

@import 'components/card';
@import 'components/color';
@import 'components/datepicker';
@import 'components/dropdown';
@import 'components/datatable';
// @import "components/form-builder";
@import 'components/form-input';
@import 'components/form-wizard';
@import 'components/forms';
// @import "components/form_builder-2";
@import 'components/icons';
@import 'components/list';
@import 'components/loader';
@import 'components/popover';
@import 'components/print';
@import 'components/radio';
@import 'components/ribbon';
@import 'components/switch';
@import 'components/tab';
@import 'components/table';
@import 'components/timePeeker';
@import 'components/touchspin';
@import 'components/tour';
@import 'components/typeahead-search';
@import 'components/react-plugin';
@import 'components/slick.scss';
@import 'components/slick-theme.scss';
@import 'components/scrollbar';

//	*** pages ***

@import 'pages/blog.scss';
@import 'pages/bookmark-app.scss';
@import 'pages/cart.scss';
@import 'pages/chart.scss';
@import 'pages/chat.scss';
@import 'pages/checkout.scss';
@import 'pages/comingsoon.scss';
@import 'pages/contacts.scss';
@import 'pages/dashboard_2.scss';
@import 'pages/dashboard_3.scss';
@import 'pages/dashboard_4.scss';
@import 'pages/dashboard_5.scss';
@import 'pages/dashboard_default.scss';
@import 'pages/ecommerce.scss';
@import 'pages/email-application.scss';
@import 'pages/errorpage.scss';
@import 'pages/faq.scss';
@import 'pages/file.scss';
@import 'pages/gallery.scss';
@import 'pages/internationalization.scss';
@import 'pages/job-search.scss';
@import 'pages/jsgrid.scss';
@import 'pages/kanban.scss';
@import 'pages/knowledgebase.scss';
@import 'pages/language.scss';
@import 'pages/learning.scss';
@import 'pages/login.scss';
@import 'pages/megaoption.scss';
@import 'pages/order-history.scss';
@import 'pages/page.scss';
@import 'pages/pricing.scss';
@import 'pages/progress.scss';
@import 'pages/projectlist.scss';
@import 'pages/social-app.scss';
@import 'pages/task.scss';
@import 'pages/timeline-v.scss';
@import 'pages/timeliny.scss';
@import 'pages/user-profile.scss';
@import 'pages/wishlist.scss';

// *** layout ***
@import 'layout/footer.scss';
@import 'layout/grid.scss';
@import 'layout/header.scss';
@import 'layout/navs.scss';
@import 'layout/search.scss';
@import 'layout/select2.scss';
@import 'layout/sidebar.scss';
@import 'layout/rtl.scss';
@import 'layout/box-layout';

/* ---------------------
	*** themes **
-----------------------*/
@import 'themes/dark';
@import 'themes/theme-customizer';
@import 'themes/responsive';
@import 'themes/update';
